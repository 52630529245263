#root {
    display: block;
    position: fixed;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 10px;
    padding: 0;
}
.App {
    display: block;
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0px;
}
.App {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-end;
    gap: 0px;
}

.AppInfo {
    width: 100%;
    flex-grow: 1;
    font-size: 0.65em;
    text-align: right;
}

.AppInfo .Name,
.AppInfo .Version {
    font-weight: bold;
}

